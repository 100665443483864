import React from 'react'
// import { useStaticQuery, graphql } from 'gatsby'
// import Img from 'gatsby-image'
import {  Text} from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
// import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

// const styles = {
//   imageWrapper: {
//     borderRadius: `default`,
//     overflow: `hidden`,
//     position: `relative`
//   },
//   button: {
//     display: [`none`, `block`],
//     position: `absolute`,
//     bottom: 4,
//     right: 4
//   },
//   grid: {
//     flexWrap: [`wrap`, `wrap`, `nowrap`],
//     ' > div + div': {
//       ml: [0, 0, 5]
//     }
//   },
//   column: {
//     flex: `auto`,
//     flexBasis: [`full`, `full`, `1/2`]
//   }
// }

export default props => {

  return (
    <>
      <Seo title='Placements' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='Placements'
            subheader='The future belongs to those who believe in the beauty of their dreams.'
          />
          <Text>
          We dedicate ourselves to enable students to be placed in innovative startups, stable mid-sized companies and reputed organizations. We understand that making a transition from the student world to the real world is a difficult process. To most companies, what matters is how well trained you are in the technology stack. We do our best to ensure that you possess those skills necessary to get through the process by training you and conducting various mock interviews. We will guide you by ensuring that you are industry ready and meet their standards.

We  refer only those students who are hard working, dedicated, provide timely completion of assignments, work on their the projects and portray constant involvements in our training program while they hone their skills. So, if you keenly show your passion and dedication to us, we will in turn contribute our level best to help you get placed. After all, this is going to benefit you at the end of the day.

Most of our students have lived up to the expectation of organizations that seek resources from us, looking for skilled candidates to take up important and challenging responsibilities at their workplace.
          </Text>
          <Divider />
          <iframe title="facebook" src="https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2Fdctacademy.bangalore%2Fphotos%2Fa.1613510055399697%2F2689542027796489&show_text=true&width=552&appId=628743398075467&height=512" style={{width:"552px", height:"512px"  , "border":"none", "overflow":"hidden", "scrolling":"no", "frameborder":"0", "allowTransparency":"true", "allow":"encrypted-media"}}></iframe>
          <Divider />
        </Main>
      </Stack>
    </>
  )
}